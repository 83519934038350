import { createContext, useContext, useState } from 'react';
import axios from 'axios';
import { constants } from '../utils/constants';
import { LoadingContext } from './loadingContext';
import { PageContext } from './context';
import { addCategoryToOfficeItem, checkForGmailLabel, getBase64FromBase64Url, getEMLFileForCase, isOfficeSetSupported } from '../utils/helperFunctions';
import ToastMessage from '../components/ToastMessage/ToastMessage';

const DmlContext = createContext();


/**
 * @summary Acts a store to house functions and state needed for saving interactions
 * @function
 * @namespace Context/dmlContext
 * @description used to house various functions and global state for components.
 * To use, import { useContext } from 'react, also import { DmlContext } from this location.
 * Then declare any function or state needed here such as in example below;
 * @example
 * const { saveContentVersions, setUnsavedAttachments } = useContext(DmlContext);
*/
const DmlProvider = ({ children }) => {
  const { setIsLoading } = useContext(LoadingContext);
  const { attachmentsConfig, caseConfig, caseId, checkForGoogleAuth, deleteLinkStorage, exchangeIdSet, existingLinks, externalId, externalIdField, googleAuthToken, googleMessageId,
    interactionId, linkStorage, logScreenBottom, logType, pipeVersion, recordTypeIdEmail, recordTypeIdMeeting, refreshSalesforceToken, requiredFields, retrieveExistingLinks,
    salesforceUser, sections, screenType, setCaseId, setSaveResultToast, setScreenType, setWarningToast, valueStorage, logError
  } = useContext(PageContext);

  const [unsavedAttachments, setUnsavedAttachments] = useState(null);
  const [useFallbackSave, setUseFallbackSave] = useState('unset');
  const [attachmentError, setAttachmentError] = useState(null);

  /**
   * @summary ...
   * @function
   * @memberof Context/dmlContext
   * @todo currently turned off, if we want to add permissions to manifest we can reactivate
   * @description ...
   * @param {string} labelId ...
  */
  const attachLabelToGmail = async (labelId) => {
    // console.log('from attach label', labelId);
    if (!labelId || labelId === undefined) return;

    let token = googleAuthToken;
    let headers = {
      headers: {
        'Authorization': 'Bearer ' + token,
        'Content-Type': 'application/json'
      }
    };

    try {
      const url = `${constants.REACT_ATTACHMENT_ENDPOINT}/me/messages/${googleMessageId}/modify`;
      const updateLabelData = { "addLabelIds": [labelId], "removeLabelIds": [] };

      const response = await axios.post(url, updateLabelData, headers);
      if (response.status === 200) {
        // success
        // console.log('success', response.data);
      };
    } catch (error) {
      if (error?.response?.status === 401) {
        const newToken = await checkForGoogleAuth();
        if (newToken) {
          try {
            token = newToken;
            const newResponse = await axios.post(url, updateLabelData, headers);

          } catch (err) {
            console.log('failed to refresh google auth token', err);
          };
        };
      } else if (error.response?.status === 404) {
        console.log('response from gmail is 404 - no email with that id exists for this google account', error);
      };
    };
  };

  /**
   * @summary ...
   * @function
   * @memberof Context/dmlContext
   * @description ...
  */
  const beginSaveInteractionProcess = async () => {
    const missingFields = [];
    if (requiredFields.length > 0) {
      requiredFields.forEach(field => {
        if (field?.logType === logType) {
          if (field?.targetObject?.indexOf('Activity_Link__c') >= 0) {
            // Check required value for Interaction Links
            if (!linkStorage[field.targetField] || linkStorage[field.targetField].length === 0) {
              missingFields.push(field.label);
            }
          } else {
            if (!valueStorage?.[field?.parentId]?.[field?.id]?.value ||
              (Array.isArray(valueStorage?.[field?.parentId]?.[field?.id]?.value) && valueStorage?.[field?.parentId]?.[field?.id]?.value?.length === 0)) {
              missingFields.push(field?.label);
            };
          }
        };
      });
    };

    if (missingFields.length > 0) {
      return openSaveErrorToast(missingFields, "Required Field(s)", 'warning');
    } else {
      setSaveResultToast(false);
    };

    const insertLinkList = []; //List of Activity_Link__c records not yet exist (has no Id)
    const deleteLinkList = []; //List of Activity_Link__c Ids to be deleted
    const recordIdMap = {}; //Key: relationship recordId, Value: Activity_Link__c Id
    // Generate a map of relationship object to existing Activity_Link__c Id
    for (let inputId in existingLinks) {
      existingLinks[inputId].forEach(item => {
        recordIdMap[item.childId] = item.recordId;
      });
    }
    // Check link storage for Activity_Link__c that is not yet created (has no Id)
    for (let fieldName in linkStorage) {
      linkStorage[fieldName].forEach(childId => {
        if (!recordIdMap[childId]) {
          const newLink = {};
          //newLink[`CT_PE__Activity__c`] = activityId;
          newLink[fieldName] = childId;
          insertLinkList.push(newLink); //Appending Activity_Link__c record to be inserted
        }
        delete recordIdMap[childId]; //Remove Activity_Link__c that should be kept
      });
    };
    //Compare delete storage against Activity_Link__c records that can be deleted
    for (let fieldName in deleteLinkStorage) {
      deleteLinkStorage[fieldName].forEach(childId => {
        if (recordIdMap[childId]) {
          deleteLinkList.push(recordIdMap[childId]); //Appending Id of Activity_Link__c record
        }
      });
    };

    setIsLoading(true);
    //let progressParams = {};
    const errorList = [];

    if (deleteLinkList.length > 0) {
      const resData = await deleteInteractionLinks(deleteLinkList);
      const errors = getErrorsFromSalesforceResponse(resData);
      if (errors) {
        errorList.push(...errors);
      };
    };

    const savedIntResult = await saveInteraction();


    if (savedIntResult) {
      if (savedIntResult.errors) {
        errorList.push(...savedIntResult.errors);
      } else {
        //progressParams.interactionUrl = `${salesforceUser?.url}/${savedIntResult.interactionId}`;
      }
      //console.log('savedIntResult.interactionId', savedIntResult.interactionId, insertLinkList)
      if (savedIntResult.interactionId) {
        if (insertLinkList.length > 0) {
          const resData = await saveInteractionLinks(savedIntResult.interactionId, insertLinkList);
          const errors = await getInteractionLinkSaveErrors(resData, insertLinkList);
          if (errors) {
            errorList.push(...errors);
          };
        };

        if (attachmentsConfig) {
          await saveContentVersions2(savedIntResult.interactionId, null);
        } else {
          await saveContentVersions(savedIntResult.interactionId);
        }
      };

      if (errorList.length > 0) {
        openSaveErrorToast(errorList);
        setIsLoading(false);
      } else {
        if (insertLinkList.length > 0 || deleteLinkList.length > 0) {
          // console.log('else', savedIntResult);
          setTimeout(async () => {
            await retrieveExistingLinks(savedIntResult.interactionId);
            setScreenType("intro");
            setIsLoading(false);
          }, insertLinkList.length > 0 ? 1000 : 0);
        } else {
          setScreenType("intro");
          setIsLoading(false);
        };
      };
    };
  };

  /**
   * @summary ...
   * @function
   * @memberof Context/dmlContext
   * @description ...
   * @param {array} deleteLinkList ...
  */
  const deleteInteractionLinks = async (deleteLinkList) => {
    const deleteURL = `${constants.REACT_SERVER_URL}/api/v3/query/remove/composite/${salesforceUser?.account}/${salesforceUser?.instance}`;
    const optionsV3 = { 'Content-Type': 'application/json', 'Accept': 'application/json', 'x-token': salesforceUser?.token, 'x-iv': salesforceUser?.iv };

    try {
      const response = await axios.delete(deleteURL, { data: { ids: deleteLinkList }, headers: optionsV3 });
      return response.data;
    } catch (error) {
      logError({
        error: error,
        functionName: 'deleteInteractionLinks',
        additionalNotes: 'Error deleting interaction links',
        displayedToUser: false,
        level: 'error'
      });
      console.log('error deleting interation links', error);
      return JSON.stringify([{ errors: [{ statusCode: "EXCEPTION", message: error }] }]);
    };
  };

  const getAttachmentData = async (locationId, messageId, aIndex, cvList) => {
    const attId = unsavedAttachments[aIndex].googleId;

    const requestUrl = `${constants.REACT_ATTACHMENT_ENDPOINT}/me/messages/${messageId}/attachments/${attId}`;
    const options = {
      headers: {
        'Authorization': 'Bearer ' + googleAuthToken,
        'Accept': 'application/json'
      }
    };

    let response;

    try {
      response = await axios.get(requestUrl, options);
    } catch (e) {
      console.log(e);
    }

    if (response?.data) {
      const contentVersion = {
        attributes: {
          type: 'ContentVersion',
        },
        PathOnClient: unsavedAttachments[aIndex].fileName,
        VersionData: getBase64FromBase64Url(response.data.data),
        FirstPublishLocationId: locationId,
        IsMajorVersion: true
      };
      cvList.push(contentVersion);
    } else {
      // ERROR HANDLING
      //return JSON.stringify([{ errors: [{ statusCode: "EXCEPTION", message: error }] }]);
    };

    if (aIndex < unsavedAttachments.length - 1) {
      return getAttachmentData(locationId, messageId, aIndex + 1, cvList);
    } else {
      return cvList;
    };
  };

  const getErrorsFromSalesforceResponse = (resData) => {
    //console.log('response.data', resData);
    const errors = [];
    if (resData?.length > 0 && Array.isArray(resData)) {
      resData.forEach(data => {
        if (!data.success && data.errors?.length > 0) {
          data.errors.forEach(err => {
            // console.log("error", err);
            errors.push(`${err.fields}: ${err.message}. ${err.statusCode}`);
          });
        }
      });
    };
    return errors.length > 0 ? errors : null;
  };

  const getInteractionLinkSaveErrors = async (resData, insertList) => {
    const errors = [];
    const lockedRecords = [];
    let errorList = [];
    // console.log('check for save errors', resData)
    if (resData?.length > 0 && Array.isArray(resData)) {
      resData.forEach(data => {
        if (!data.success && data.errors?.length > 0) {
          data.errors.forEach((err, index) => {
            //console.log("error", err, insertList[index]);
            if (!err.statusCode) {
              errors.push(err);
            } else if (err.statusCode === 'UNABLE_TO_LOCK_ROW') {
              lockedRecords.push(insertList[index]);
              errorList.push(err);
            } else {
              errors.push(`${err.fields}: ${err.message}. ${err.statusCode}`);
            };
          });
        };
      });
    };
    if (lockedRecords.length > 0) {
      return await handleLockedRecordsOnSave(lockedRecords, errorList);
    } else {
      return errors.length > 0 ? errors : null;
    };
  };

  const getRecordForSave = () => {
    const newRecord = {};
    for (let sKey in valueStorage) {
      for (let iKey in valueStorage[sKey]) {
        const inputStorage = valueStorage[sKey][iKey];
        if (inputStorage.targetField) {
          if (inputStorage.value) {
            switch (inputStorage.type) {
              case "email":
                if (typeof inputStorage.value === 'string') {
                  newRecord[inputStorage.targetField] = inputStorage.value;
                } else {
                  newRecord[inputStorage.targetField] = inputStorage.value?.join("; ");
                };
                break;
              case "checkbox":
                newRecord[inputStorage.targetField] = !!inputStorage.value;
                break;
              case "datetime":
                newRecord[inputStorage.targetField] = inputStorage.value;
                break;
              case "lookup":
                newRecord[inputStorage.targetField] = inputStorage.value?.value;
                break;
              case "picklist":
                if (typeof inputStorage.value === 'object') {
                  const picklistValues = [];
                  inputStorage.value?.forEach(option => {
                    picklistValues.push(option.value);
                  });
                  newRecord[inputStorage.targetField] = picklistValues.join(";");
                } else {
                  newRecord[inputStorage.targetField] = inputStorage.value;
                };
                break;
              default:
                if (typeof inputStorage.value === 'object') {
                  if (inputStorage.value.length > 0) {
                    newRecord[inputStorage.targetField] = inputStorage.value?.join(";");
                  } else {
                    newRecord[inputStorage.targetField] = inputStorage.value;
                  };
                } else {
                  newRecord[inputStorage.targetField] = inputStorage.value;
                };
            };
          } else {
            newRecord[inputStorage.targetField] = inputStorage.type === 'checkbox' ? false : null;
          };
        };
      };
    };

    if (screenType === "interaction") {
      if (!newRecord.RecordTypeId) {
        newRecord.RecordTypeId = logType === 'email' ? recordTypeIdEmail : recordTypeIdMeeting;
      };
      if (!!externalIdField && !!externalId && !exchangeIdSet) {
        newRecord[externalIdField] = externalId;
      } else if (exchangeIdSet) {
        exchangeIdSet.forEach((item) => {
          for (const [key, value] of Object.entries(item)) {
            newRecord[key] = value;
          };
        });
      };
      if (pipeVersion >= 16) {
        newRecord.CT_PE__Provide_Last_Edit_Source__c = "Email Addin";
        if (!interactionId) {
          newRecord.CT_PE__Creation_Source__c = "Email Addin";
        }
      }
    } else if (screenType === "case") {
      if (pipeVersion >= 14 && !caseId) {
        let restId;
        try {
          restId = Office.context.mailbox.convertToRestId(Office.context.mailbox.item.itemId, Office.MailboxEnums.RestVersion.v2_0);
          // console.log(Office.context.mailbox.item.itemId, restId);
        } catch (e) { console.log(e); }
        if (restId) {
          newRecord.CT_PE__Outlook_Email_Identifier__c = restId;
        }
      }
      if (!!caseConfig?.externalIdField && !!externalId) {
        // TODO update this to > 13 once testing complete
        if (exchangeIdSet && pipeVersion > 15) {
          exchangeIdSet.forEach((item) => {
            for (const [key, value] of Object.entries(item)) {
              newRecord[key] = value;
            };
          });
        } else {
          newRecord[caseConfig.externalIdField] = externalId;
        };
      };
    };
    // console.log('new record for Save', newRecord);
    return newRecord;
  };

  const getOutlookAttachmentData = async (locationId) => {
    const requests = [];
    const item = await Office.context.mailbox.item;
    const attachments = await item.attachments;
    if (attachments.length <= 0) {
      console.log("Mail item has no attachments.");
      return requests;
    };

    for (let i = 0; i < attachments.length; i++) {
      requests.push(
        new Promise((resolve, reject) => {
          item.getAttachmentContentAsync(attachments[i].id, (result) => {
            // console.log('result', result);
            let dataList = [];
            if (result?.value?.format === "base64") {
              const contentVersion = {
                attributes: {
                  type: 'ContentVersion',
                },
                PathOnClient: attachments[i].name?.replaceAll('+', '_'),
                VersionData: result.value?.content,
                FirstPublishLocationId: locationId,
                IsMajorVersion: true
              };
              dataList.push(contentVersion);
            } else {
              console.log('not base64 format need to update', result);
            };
            // console.log('dataList', dataList);
            return resolve(dataList);
          });
        })
      );
    };

    const result = await Promise.all(requests);
    const list = result.flatMap((item) => item);
    // console.log('list', list);
    return list;
  };

  const handleLockedRecordsOnSave = async (lockedRecords, errorList) => {
    let fallback = useFallbackSave;
    const options = { headers: { 'Content-Type': 'application/json' } };
    const optionsV3 = { headers: { 'Content-Type': 'application/json', 'Accept': 'application/json', 'x-token': salesforceUser?.token, 'x-refresh': salesforceUser?.refresh, 'x-iv': salesforceUser?.iv } }
    if (fallback === 'unset') {
      const query = `SELECT+CT_PE__Use_Fallback_Save_For_Interactions__c+FROM+CT_PE__Org_Setting__c+WHERE+SetupOwnerId='${salesforceUser.org}'`;
      const url = `${constants.REACT_SERVER_URL}/api/v3/query/soql/${salesforceUser?.account}/${salesforceUser?.instance}}?q=${query}`;
      try {
        const response = await axios.get(url, optionsV3);
        //console.log(response?.data?.records, response?.data?.records?.[0]?.CT_PE__Use_Fallback_Save_For_Interactions__c);
        fallback = !!response?.data?.records?.[0]?.CT_PE__Use_Fallback_Save_For_Interactions__c;
        setUseFallbackSave(fallback);
      } catch (e) {
        console.warn('Failed to retrieve CT_PE__Org_Setting__c.', e);
      };
    };

    let failedBackup = true;
    if (fallback === true) {
      const eventLog = {
        CT_PE__Source_Name__c: constants.EMAIL_CLIENT === 'outlook' ? 'Outlook Addin' : 'Gmail Extension',
        CT_PE__Source_Type__c: 'Save Interaction Links Process',
        CT_PE__Exception_Class_Name__c: 'context.js',
        CT_PE__Exception_Method_Name__c: 'handleLockedRecordsOnSave',
        CT_PE__Exception_Occured__c: true,
        CT_PE__Exception_Severity__c: 'Error'
      };
      let eventLogId;
      try {
        const url = `${constants.REACT_SERVER_URL}/api/v3/query/create/${salesforceUser?.account}/${salesforceUser?.instance}?o=CT_PE__System_Event_Log__c`;
        const response = await axios.post(url, JSON.stringify(eventLog), optionsV3);
        eventLogId = response.data?.id;
      } catch (e) {
        console.warn('Failed to save CT_PE__System_Event_Log__c.', e);
      };

      if (!!eventLogId) {
        const eventLogData = {
          CT_PE__Status__c: 'pending',
          CT_PE__Error_Message__c: JSON.stringify(errorList),
          CT_PE__Target_Object__c: 'CT_PE__Activity_Link__c',
          CT_PE__System_Event_Log__c: eventLogId,
          CT_PE__Parent_Id__c: interactionId,
          CT_PE__Data__c: JSON.stringify(lockedRecords)
        }
        try {
          const url = `${constants.REACT_SERVER_URL}/api/v3/query/create/${salesforceUser?.account}/${salesforceUser?.instance}?o=CT_PE__System_Event_Log_Data__c`;
          await axios.post(url, JSON.stringify(eventLogData), optionsV3);
        } catch (e) {
          console.warn('Failed to save CT_PE__System_Event_Log_Data__c.', e);
        }
        // success message
        setSaveResultToast(
          <ToastMessage
            message={
              <div>
                <p>
                  Your Interaction was saved successfully, however there was an error when saving your selected Relationships. All relationship inputs were backed up successfully.
                </p>
              </div>
            }
            severity={'warning'}
            title={"Alert!"}
            type={'alert'} />
        );
        failedBackup = false;
      };
    };

    if (failedBackup) {
      const errorResults = ['Your Interaction was saved successfully, however there was an error when saving your selected Relationships. Please try again.'];
      errorList.forEach(err => {
        errorResults.push(`${err.fields}: ${err.message}. ${err.statusCode}`);
      });
      return errorResults;
    };
    return null;
  };

  const saveCase = async () => {
    const isNewCase = !caseId ? 'new case' : 'update';
    const missingFields = [];
    if (requiredFields.length > 0) {
      requiredFields.forEach(field => {
        if (field?.logType === 'case') {
          if (!valueStorage?.[field?.parentId]?.[field?.id]?.value ||
            (Array.isArray(valueStorage?.[field?.parentId]?.[field?.id]?.value) && valueStorage?.[field?.parentId]?.[field?.id]?.value?.length === 0)) {
            missingFields.push(field?.label)
          };
        };
      });
    };

    if (missingFields.length > 0) {
      return openSaveErrorToast(missingFields, "Required Field(s)", 'warning');
    } else {
      setSaveResultToast(false);
    };

    setIsLoading(true);
    const newCase = getRecordForSave();

    let response;
    const optionsV3 = { headers: { 'Content-Type': 'application/json', 'Accept': 'application/json', 'x-token': salesforceUser?.token, 'x-refresh': salesforceUser?.refresh, 'x-iv': salesforceUser?.iv } };
    let url = `${constants.REACT_SERVER_URL}/api/v3/query/create/${salesforceUser?.account}/${salesforceUser?.instance}?o=${caseConfig.targetObject}`;
    try {
      if (!!caseId) {
        url = `${constants.REACT_SERVER_URL}/api/v3/query/update/${salesforceUser?.account}/${salesforceUser?.instance}/${caseId}?o=${caseConfig.targetObject}`;
        response = await axios.patch(url, JSON.stringify(newCase), optionsV3);
      } else {
        response = await axios.post(url, JSON.stringify(newCase), optionsV3);
      };
    } catch (error) {
      console.log('error saving case', error);
      if (error?.status === 401 || error?.response?.status === 401) {
        refreshSalesforceToken();
      } else {
        logError({
          functionName: 'saveCase',
          error: error,
          additionalNotes: 'Error saving case',
          displayedToUser: true,
          level: 'error'
        });
      };

      const errorCode = error?.response?.data?.errorCode;
      const errorFields = [];
      if (error?.response?.data?.fields?.length > 0) {
        error?.response?.data?.fields.forEach(field => {
          const foundLabel = caseConfig.sections.some(s => {
            return s.inputFields.some(config => {
              if (config.targetField === field) {
                if (errorCode === "STRING_TOO_LONG") {
                  errorFields.push(`${config.label}: ${newCase[field]?.length} / ${config.attributes?.maxLength}`);
                } else {
                  errorFields.push(config.label);
                }
                return true;
              }
            });
          });
          if (!foundLabel) {
            errorFields.push(field);
          }
        });
      };
      const errorList = [];
      if (errorCode === "STRING_TOO_LONG") {
        errorList.push("Data value is too large");
      } else if (errorCode === "FIELD_CUSTOM_VALIDATION_EXCEPTION") {
        errorList.push("Please check the validation rules for Case");
      } else if (errorCode === "INVALID_FIELD_FOR_INSERT_UPDATE") {
        errorList.push("The following field cannot be updated as part of the API call");
      } else {
        errorList.push(errorCode);
      }
      errorList.push(...errorFields);
      if (error?.response?.data?.message) {
        errorList.push(error.response.data.message);
      }

      openSaveErrorToast(errorList);
    };

    const errorList = getErrorsFromSalesforceResponse(response?.data);
    if (errorList) {
      openSaveErrorToast(errorList);
    };

    if (response?.data?.success === true) {
      // console.log("response success", response);
      const newCaseId = response?.data?.id ? response?.data?.id : null;

      setCaseId(newCaseId);
      if (attachmentsConfig) {
        await saveContentVersions2(null, newCaseId);
        if (salesforceUser?.createEml) {
          await saveEmlFile(newCaseId, isNewCase);
        };
      };

      setScreenType("intro");
      if (constants.EMAIL_CLIENT === 'outlook') {
        // TODO: load and set custom param? do we want the decorator for cases??
      };
    };
    setIsLoading(false);
  };

  const saveContentVersions = async (newInteractionId) => {
    if (unsavedAttachments && unsavedAttachments.length > 0) {
      const cvList = constants.EMAIL_CLIENT === 'outlook' ? await getOutlookAttachmentData(newInteractionId || interactionId) : await getAttachmentData(newInteractionId || interactionId, externalId.split(";").pop(), 0, []);
      // filter the full list against the selected unsaved attachments
      const filterdList = cvList.filter(item => unsavedAttachments.map(unsaved => unsaved.fileName).includes(item.PathOnClient?.replaceAll('+', '_')));
      const postUrl = `services/data/${constants.REACT_SF_API_VERSION}/composite/sobjects/`;
      const compositeURL = `${constants.REACT_SERVER_URL}/api/v3/query/composite/${salesforceUser?.account}/${salesforceUser?.instance}`;
      const body = { data: { 'allOrNone': false, 'records': filterdList }, postUrl: postUrl };
      const optionsV3 = { headers: { 'Content-Type': 'application/json', 'Accept': 'application/json', 'x-token': salesforceUser?.token, 'x-iv': salesforceUser?.iv } };

      try {
        const response = await axios.post(compositeURL, body, optionsV3);
        if (!response?.data?.[0]?.errors || response?.data?.[0]?.errors?.length === 0) {
          // Success
          //  console.log('saveContentVersions success response', result)
        } else {
          //ERROR HANDLING
          // console.log('saveContentVersions error response', response?.data?.[0]?.errors)
          //return JSON.stringify([{ errors: [{ statusCode: "EXCEPTION", message: error }] }]);
        };
      } catch (e) {
        if (e.code === 'ERR_NETWORK') {
          console.log('likely file is too large for server - should we send error message?');
        };
        console.log('error fetching saved content versions', e);
      };
    };
  };

  /**
   * @summary Save Attachments to Salesforce and link to Case/Interaction using ContentVersion and ContentDocumentLink
   * @function saveContentVersions2
   * Same purpose as saveContentVersions but has logic to create ContentDocumentLink
   * @memberof Context/dmlContext
   * @description ...
  */
  const saveContentVersions2 = async (newInteractionId, newCaseId) => {
    const unsavedList = [];
    const cvInsertList = [];

    const locationIdInteraction = newInteractionId || interactionId;
    const locationIdCase = newCaseId || caseId;
    // console.log("saveContentVersions2", unsavedAttachments);
    if (unsavedAttachments && unsavedAttachments.length > 0) {
      const cvList = constants.EMAIL_CLIENT === 'outlook' ?
        await getOutlookAttachmentData('')
        : await getAttachmentData('', externalId.split(";").pop(), 0, []);

      //console.log('cvList', cvList);
      //console.log('unsavedAttachments', unsavedAttachments);
      unsavedAttachments.forEach(att => {
        if (att.docId) {
          unsavedList.push(att)
        } else {
          cvList.some(cv => {
            if (cv.PathOnClient === att.fileName) {
              if (attachmentsConfig?.defaultValues) {
                Object.keys(attachmentsConfig.defaultValues).forEach(fieldName => {
                  cv[fieldName] = attachmentsConfig.defaultValues[fieldName];
                });
              };
              // Check if case and interaction Id exists for insertion
              if (att.uploadToCase && locationIdCase) {
                cv.FirstPublishLocationId = locationIdCase;
                cvInsertList.push(cv);
              } else if (att.uploadToInteraction && locationIdInteraction) {
                cv.FirstPublishLocationId = locationIdInteraction;
                cvInsertList.push(cv);
              }
              return true;
            };
          });
        };
      });
    };

    // console.log("ContentVersions to be inserted", cvInsertList);
    if (cvInsertList.length > 0) {
      const postUrl = `services/data/${constants.REACT_SF_API_VERSION}/composite/sobjects/`;
      let compositeURL = `${constants.REACT_SERVER_URL}/api/v3/query/composite/${salesforceUser?.account}/${salesforceUser?.instance}`;
      const body = { data: { 'allOrNone': false, 'records': cvInsertList }, postUrl: postUrl };
      let optionsV3 = { headers: { 'Content-Type': 'application/json', 'Accept': 'application/json', 'x-token': salesforceUser?.token, 'x-iv': salesforceUser?.iv } };

      let response;
      try {
        // Save ContentVersion for attachments that do not exist in the org
        response = await axios.post(compositeURL, body, optionsV3);
        // console.log(response);
      } catch (e) {
        console.log('error fetching saved content versions', e);
        if (e?.response?.data?.status === 401) {
          const newToken = await refreshSalesforceToken();
          if (newToken?.error) {
            console.log('JS exception - add error message', e, newToken?.error);
          } else {
            try {
              optionsV3 = { headers: { 'Content-Type': 'application/json', 'Accept': 'application/json', 'x-token': newToken?.token?.token, 'x-iv': newToken?.token?.iv } };
              response = await axios.post(compositeURL, body, optionsV3);
              return response.data;
            } catch (error) {
              console.log('JS exception while - add error message', error);
            };
          };
        } else {
          console.log('error message here -- --', e);
        };
      };

      if (response?.data && (!response?.data?.[0]?.errors || response?.data?.[0]?.errors?.length === 0)) {
        if (unsavedAttachments && unsavedAttachments.length > 0) {
          // console.log("Inserted ContentVersion", response.data);
          const cdIdList = [];
          response?.data.forEach(newCV => {
            cdIdList.push(newCV.id);
          });
          // Query for ContentVersions that were just saved to get ContentDocumentId
          const soqlString = `SELECT ContentDocumentId,PathOnClient,Title,FirstPublishLocationId FROM ContentVersion WHERE Id IN ('${cdIdList.join("','")}')`;
          const optionsV3 = { headers: { 'Content-Type': 'application/json', 'Accept': 'application/json', 'x-token': salesforceUser?.token, 'x-refresh': salesforceUser?.refresh, 'x-iv': salesforceUser?.iv } };
          const url = `${constants.REACT_SERVER_URL}/api/v3/query/soql/${salesforceUser?.account}/${salesforceUser?.instance}`;
          try {
            const request = await axios.post(url, { data: JSON.stringify(soqlString) }, optionsV3);
            // console.log("Query for ContentVersions", request.data);

            if (!request?.data?.[0]?.errors || request?.data?.[0]?.errors?.length === 0) {
              // Process attachment against saved ContentVersion in order to share the correct ContentDocumentId
              request.data.records.forEach(record => {
                unsavedAttachments.forEach(att => {
                  if (!att.docId && att.uploadToCase && att.uploadToInteraction && att.fileName === record.PathOnClient) {
                    // Assign ContentDocumentId in order to share attachments
                    att.docId = record.ContentDocumentId;
                    if (record.FirstPublishLocationId === locationIdCase) {
                      att.savedToCase = true;
                      att.uploadToCase = false;
                    } else if (record.FirstPublishLocationId === locationIdInteraction) {
                      att.savedToInteraction = true;
                      att.uploadToInteraction = false;
                    };
                    unsavedList.push(att);
                  };
                });
              });
            } else {
              console.log('Get ContentVersion after save error', request?.data?.[0]?.errors);
            };
          } catch (error) {
            console.warn('error fetching ContentVersion data', error);
          };
          setAttachmentError(null);
          // Success
          // console.log('saveContentVersions success request', response)
        }
      } else {
        //ERROR HANDLING
        console.log('saveContentVersions error response where in error message for UI', response);
        return generateErrorMap_ContentVersion(response.data, cvInsertList);
      };
    };
    //console.log("Unsaved List for ContentDocumentLink", unsavedList);
    await saveContentDocumentLinks(unsavedList, locationIdCase, locationIdInteraction);
    setUnsavedAttachments(null);
  };

  const saveContentDocumentLinks = async (unsavedList, locationIdCase, locationIdInteraction) => {
    if (unsavedList && unsavedList.length > 0) {
      const cdlInsertList = [];
      unsavedList.forEach(att => {
        if (att.docId) {
          const cdl = {
            ContentDocumentId: att.docId,
            attributes: {
              type: 'ContentDocumentLink'
            }
          };
          if (att.uploadToCase && !att.savedToCase && locationIdCase) {
            cdl.LinkedEntityId = locationIdCase;
          } else if (att.uploadToInteraction && !att.savedToInteraction && locationIdInteraction) {
            cdl.LinkedEntityId = locationIdInteraction;
          };
          if (cdl.LinkedEntityId) {
            cdlInsertList.push(cdl);
          };
        };
      });
      if (cdlInsertList.length === 0) return;

      const postUrl = `services/data/${constants.REACT_SF_API_VERSION}/composite/sobjects/`;
      const compositeURL = `${constants.REACT_SERVER_URL}/api/v3/query/composite/${salesforceUser?.account}/${salesforceUser?.instance}`;
      const body = { data: { 'allOrNone': false, 'records': cdlInsertList }, postUrl: postUrl };
      let optionsV3 = { headers: { 'Content-Type': 'application/json', 'Accept': 'application/json', 'x-token': salesforceUser?.token, 'x-iv': salesforceUser?.iv } };

      try {
        const response = await axios.post(compositeURL, body, optionsV3);
        if (!response?.data?.[0]?.errors || response?.data?.[0]?.errors?.length === 0) {
          // Success
          //  console.log('saveContentDocumentLinks success response', result)
        } else {
          //ERROR HANDLING
          console.log('saveContentDocumentLinks error response', response?.data?.[0]?.errors);
          // return JSON.stringify([{ errors: [{ statusCode: "EXCEPTION", message: error }] }]);
        }
      } catch (error) {
        if (error?.response?.data?.status === 401) {
          const newToken = await refreshSalesforceToken();
          if (newToken?.error) {
            console.log('JS exception while fetching saved content versions', error, newToken?.error);
          } else {
            try {
              optionsV3 = { headers: { 'Content-Type': 'application/json', 'Accept': 'application/json', 'x-token': newToken?.token?.token, 'x-iv': newToken?.token?.iv } };
              await axios.post(compositeURL, body, optionsV3);
              // Success
            } catch (error) {
              logError({
                functionName: 'saveContentDocumentLinks',
                error: error,
                additionalNotes: 'Exception occurred while fetching saved content versions',
                displayedToUser: false,
                level: 'error'
              });
              console.log('catach eror while fetching saved content versions', error);
            };
          };
        } else {
          console.log('error fetching saved content versions', error);
        };
      };
    };
  };

  const saveEmlFile = async (newCaseId, isNewCase) => {
    if (isNewCase === 'new case' && !!newCaseId) {
      if (constants.EMAIL_CLIENT === 'outlook' && isOfficeSetSupported('1.5')) {
        // create a .eml file
        const emlfile = await getEMLFileForCase();
        if (emlfile?.status === 'success') {
          // console.log('emlfile', emlfile);
          const emlContentVersion = {
            attributes: {
              type: 'ContentVersion',
            },
            PathOnClient: emlfile.file.filename,
            VersionData: emlfile.file.caseEML,
            FirstPublishLocationId: newCaseId,
            IsMajorVersion: true
          };

          const postUrl = `services/data/${constants.REACT_SF_API_VERSION}/composite/sobjects/`;
          const compositeURL = `${constants.REACT_SERVER_URL}/api/v3/query/composite/${salesforceUser?.account}/${salesforceUser?.instance}`;
          let optionsV3 = { headers: { 'Content-Type': 'application/json', 'Accept': 'application/json', 'x-token': salesforceUser?.token, 'x-iv': salesforceUser?.iv } };
          const body = { data: { 'allOrNone': false, 'records': [emlContentVersion] }, postUrl: postUrl };

          let response;
          try {
            // Save ContentVersion for attachments that do not exist in the org
            response = await axios.post(compositeURL, body, optionsV3);
            // console.log(response);
          } catch (e) {
            console.log('error fetching saved content versions', e);
            if (e?.response?.data?.status === 401) {
              const newToken = await refreshSalesforceToken();
              if (newToken?.error) {
                console.log('JS exception - add error message', e, newToken?.error);
              } else {
                try {
                  optionsV3 = { headers: { 'Content-Type': 'application/json', 'Accept': 'application/json', 'x-token': newToken?.token?.token, 'x-iv': newToken?.token?.iv } };
                  response = await axios.post(compositeURL, body, optionsV3);
                  return response.data;
                } catch (error) {
                  console.log('JS exception while - add error message', error);
                };
              };
            } else {
              console.log('error message here -- --', e);
            };
          };
        } else {
          openSaveErrorToast([emlfile.message], "Failed to save .eml file", 'warning');
          logError({
            functionName: 'saveEmlFile',
            error: emlfile.message,
            additionalNotes: 'Failed to save .eml file',
            displayedToUser: true,
            level: 'warn'
          });
          console.log('failed to save eml file', emlfile);
        };
      };
    };
  };

  const saveInteraction = async () => {
    const newInteraction = getRecordForSave();
    let url = `${constants.REACT_SERVER_URL}/api/v3/query/create/${salesforceUser?.account}/${salesforceUser?.instance}?o=CT_PE__Activity__c`;
    let optionsV3 = { headers: { 'Content-Type': 'application/json', 'Accept': 'application/json', 'x-token': salesforceUser?.token, 'x-refresh': salesforceUser?.refresh, 'x-iv': salesforceUser?.iv } };
    let response;
    const errorList = [];

    try {
      if (!!interactionId) {
        url = `${constants.REACT_SERVER_URL}/api/v3/query/update/${salesforceUser?.account}/${salesforceUser?.instance}/${interactionId}?o=CT_PE__Activity__c`;
        response = await axios.patch(url, JSON.stringify(newInteraction), optionsV3);
      } else {
        response = await axios.post(url, JSON.stringify(newInteraction), optionsV3);
      };
    } catch (e) {
      console.warn('error creating interaction', e);
      if (e?.status === 401 || e?.response?.status === 401) {
        // retry with new token
        const newToken = await refreshSalesforceToken();
        if (newToken?.error || !newToken?.token) {
          // console.log('SF access token expired while saving interaction', e, newToken?.error);
          errorList.push(e?.response?.data?.code);
          errorList.push('Interaction May Not Have Saved');
          errorList.push('Please Try Logging Interaction Again');
        } else {
          try {
            optionsV3 = { headers: { 'Content-Type': 'application/json', 'Accept': 'application/json', 'x-token': newToken?.token?.token, 'x-refresh': salesforceUser?.refresh, 'x-iv': newToken?.token?.iv } };
            if (!!interactionId) {
              response = await axios.patch(url, JSON.stringify(newInteraction), optionsV3);
            } else {
              response = await axios.post(url, JSON.stringify(newInteraction), optionsV3);
            };
            // return response.data;
          } catch (error) {
            logError({
              functionName: 'saveInteraction',
              error: error,
              additionalNotes: 'Exception occurred while saving interaction',
              displayedToUser: true,
              level: 'error'
            });
            console.log('JS exception while saving interation', error);
            errorList.push(error?.response?.data?.code);
            errorList.push('Interaction May Not Have Saved');
            errorList.push('Please Try Logging Interaction Again');
          };
        };
      } else {
        const errorCode = e?.response?.data?.errorCode;
        const errorFields = [];
        if (e?.response?.data?.fields?.length > 0) {
          e?.response?.data?.fields.forEach(field => {
            const foundLabel = sections.some(s => {
              return s.inputFields.some(config => {
                if (config.targetObject === "CT_PE__Activity__c" && config.targetField === field) {
                  if (errorCode === "STRING_TOO_LONG") {
                    errorFields.push(`${config.label}: ${newInteraction[field]?.length} / ${config.attributes?.maxLength}`);
                  } else {
                    errorFields.push(config.label);
                  };
                  return true;
                };
              });
            });
            if (!foundLabel) {
              errorFields.push(field);
            };
          });
        };

        const errorList = [];
        if (errorCode === "STRING_TOO_LONG") {
          errorList.push("Data value is too large");
        } else if (errorCode === "FIELD_CUSTOM_VALIDATION_EXCEPTION") {
          errorList.push("Please check the validation rules for Interaction");
        } else if (errorCode === "INVALID_FIELD_FOR_INSERT_UPDATE") {
          errorList.push("The following field cannot be updated as part of the API call");
        } else {
          errorList.push(errorCode);
        }
        errorList.push(...errorFields);
        if (e?.response?.data?.message) {
          errorList.push(e.response.data.message);
        }

        logError({
          functionName: 'saveInteraction',
          error: e,
          additionalNotes: 'Error saving interaction',
          displayedToUser: false,
          level: 'error'
        });
        setIsLoading(false);
        return openSaveErrorToast(errorList);

      };
    };

    let savedActivityId = interactionId;

    if (response?.data?.success === true) {
      // console.log("response success", response);
      savedActivityId = response.data?.id;
      if (constants.EMAIL_CLIENT === 'outlook') {
        try {
          Office.context.mailbox.item.loadCustomPropertiesAsync(function (asyncResult) {
            let customProps = asyncResult.value;
            if (customProps) {
              customProps.set('sfdcId', savedActivityId);
              customProps.saveAsync(function (saveResult) {
                if (saveResult.status === Office.AsyncResultStatus.Failed) {
                  console.log("Error:", saveResult.error.message);
                  logError({
                    functionName: 'saveInteraction',
                    error: saveResult?.error?.message,
                    additionalNotes: 'loadCustomPropertiesAsync error - shared email/calendar?',
                    displayedToUser: false,
                    level: 'warn'
                  });
                } else {
                  if (isOfficeSetSupported('1.8') && salesforceUser?.category) {
                    addCategoryToOfficeItem(salesforceUser?.category);
                  };
                }
              })
            };
          });
        } catch (e) {
          console.log('sfdcId property could not be set', e);
        };
        // need to add manifest permisions in order to start using this
        // } else if (salesforceUser?.category && salesforceUser?.decoratorEnabled) {
        //   const labelId = await checkForGmailLabel(salesforceUser?.category, googleAuthToken, salesforceUser?.categoryColor);
        //   attachLabelToGmail(labelId);
      };
    };

    return {
      interactionId: savedActivityId,
      errors: getErrorsFromSalesforceResponse(response?.data)
    };
  };

  const saveInteractionLinks = async (activityId, insertLinkList) => {
    // console.log('activityId, insertLinkList', activityId, insertLinkList)
    //const linkRecords = [];
    insertLinkList.forEach(linkRecord => {
      linkRecord.attributes = { type: `CT_PE__Activity_Link__c` };
      linkRecord[`CT_PE__Activity__c`] = activityId;
    });

    const postUrl = `services/data/${constants.REACT_SF_API_VERSION}/composite/sobjects/`;
    const compositeURL = `${constants.REACT_SERVER_URL}/api/v3/query/composite/${salesforceUser?.account}/${salesforceUser?.instance}`;
    const body = { data: { 'allOrNone': false, 'records': insertLinkList }, postUrl: postUrl };
    let optionsV3 = { headers: { 'Content-Type': 'application/json', 'Accept': 'application/json', 'x-token': salesforceUser?.token, 'x-iv': salesforceUser?.iv } };

    try {
      const response = await axios.post(compositeURL, body, optionsV3);
      return response.data;
    } catch (error) {
      const errorList = [];
      // console.log('JS exception while saving interation links', error);
      error?.response?.data?.errorCode && errorList.push(error?.response?.data?.errorCode);
      if (error?.response?.data?.status === 401) {
        const newToken = await refreshSalesforceToken();
        if (newToken?.error || !newToken?.token) {
          // console.log('JS exception while saving interation links', error, newToken?.error);
          errorList.push(error?.response?.data?.code);
          errorList.push('Interaction Links May Not Have Saved');
          errorList.push('Please Try Logging Interaction Again');
        } else {
          try {
            optionsV3 = { headers: { 'Content-Type': 'application/json', 'Accept': 'application/json', 'x-token': newToken?.token?.token, 'x-iv': newToken?.token?.iv } };
            const response = await axios.post(compositeURL, body, optionsV3);
            return response.data;
          } catch (error) {
            logError({
              functionName: 'saveInteractionLinks',
              error: error,
              additionalNotes: 'Exception occurred while saving interaction links',
              displayedToUser: true,
              level: 'error'
            });
            console.log('JS exception while saving interation links', error);
            errorList.push(error?.response?.data?.code);
            errorList.push('Interaction Links May Not Have Saved');
            errorList.push('Please Try Logging Interaction Again');
          };
        };
      } else {
        if (error?.response?.data?.fields?.length > 0) {
          error?.response?.data?.fields.forEach(field => {
            errorList.push(field);
          });
        } else {
          errorList.push(error?.response?.data?.code);
        };
      };
      setIsLoading(false);
      return { success: false, errors: errorList };
      // return openSaveErrorToast(errorList);
    };
  };

  // ERROR HANDLING
  const generateErrorMap_ContentVersion = (data, cvList) => {
    let errorMap;
    if (typeof data.forEach === "function") {
      errorMap = {};
      data.forEach((result, index) => {
        if (result.errors?.length > 0) {
          const fileName = cvList[index].PathOnClient;
          errorMap[fileName] = [];
          result.errors.forEach(error => {
            errorMap[fileName].push({
              message: error.message,
              fields: error.fields ? error.fields.join(", ") : ""
            });
          });
        }
      });
    }
    setAttachmentError(errorMap);
    return errorMap;
  };

  const openSaveErrorToast = (errorList, title, severity) => {
    setSaveResultToast(
      <div style={{ paddingTop: "0.5rem" }}>
        <ToastMessage
          message={
            <ul style={{ marginLeft: "-1.5rem" }}>
              {errorList.map((msg, key) => (
                <li style={{ wordBreak: 'break-word' }} key={`${key}-save-error`}>{msg}</li>
              ))}
            </ul>
          }
          severity={severity ? severity : 'error'}
          title={title ? title : 'Save errors!'}
          type={'alert'} />
      </div>
    );
    setTimeout(() => { logScreenBottom?.current?.scrollIntoView({ behavior: 'smooth' }); });
  };


  return (
    <DmlContext.Provider value={{
      attachmentError,
      beginSaveInteractionProcess,
      saveCase,
      saveContentVersions,
      saveContentVersions2,
      unsavedAttachments, setUnsavedAttachments,
    }}>
      {children}
    </DmlContext.Provider>
  );
};

export { DmlContext, DmlProvider };
