/* eslint-disable no-undef */
/**
 * @fileOverview functions used to parse outlook Item
 * @namespace outlookItemFunctions
*/
import { getExtendedIds, getEventOutlookUid, getIcalUidFromGraph, restructureHTML } from './helperFunctions';


const buildOfficeExchangeItem = async (item) => {
  await Office.context.mailbox.item.saveAsync(function(result) {
    item.itemId = result.value;
  });
  await Office.context.mailbox.item.end.getAsync(function(result) {
    item.ewsEnd = result.value;
  });
  await Office.context.mailbox.item.location.getAsync(function(result) {
    item.ewsLocation = result.value;
  });
  await Office.context.mailbox.item.optionalAttendees.getAsync(function(result) {
    item.ewsOptionalAttendees = result.value;
  });
  await Office.context.mailbox.item.organizer.getAsync(function(result) {
    item.ewsOrganizer = result.value;
  });
  await Office.context.mailbox.item.requiredAttendees.getAsync(function(result) {
    item.ewsRequiredAttendees = result.value;
  });
  await Office.context.mailbox.item.start.getAsync(function(result) {
    item.ewsStart = result.value;
  });
  await Office.context.mailbox.item.subject.getAsync(function(result) {
    item.ewsSubject = result.value;
  });

  return item;
};

const getBCC = async (item, _item) => {
  return (new Promise(function (resolve) {
    if (item?.bcc?.getAsync) {
      item.bcc.getAsync(function (result) {
        try {
          resolve(_item.bcc = result.value);
        } catch (e) {
          console.log('getBCC Error: ', e);
          resolve(_item.bcc = '');
        };
      });
    } else {
      resolve(_item.bcc = item.bcc);
    };
  }));
};

const getBody = async (item, _item) => {
  return (new Promise(function (resolve) {
    if (item?.body?.getAsync) {
      item.body.getAsync("html", function (result) {
        try {
          const body = restructureHTML(result.value);
          resolve(item.itemType !== "message" ? _item.calendarbody = body : _item.emailbody = body);
        } catch (e) {
          console.log('getBody ERROR: ', e);
          resolve(item.itemType !== "message" ? _item.calendarbody = '' : _item.textemailbody = '');
        };
      });
    } else {
      resolve(item.itemType !== "message" ? _item.calendarbody = '' : _item.textemailbody = '');
    };
  }));
};

const getBodyText = async (item, _item) => {
  return (new Promise(function (resolve) {
    if (item?.body?.getAsync) {
      item?.body?.getAsync("text", function (result) {
        try {
          resolve(item.itemType !== "message" ? _item.textcalendarbody = result.value : _item.textemailbody = result.value);
        } catch (e) {
          console.log('getBody Text ERROR: ', e);
          resolve(item.itemType !== "message" ? _item.textcalendarbody = '' : _item.textemailbody = '');
        };
      });
    } else {
      resolve(item.itemType !== "message" ? _item.textcalendarbody = '' : _item.textemailbody = '');
    };
  }));
};

const getCC = async (item, _item) => {
  return (new Promise(function (resolve) {
    if (item?.cc?.getAsync) {
      item.cc.getAsync(function (result) {
        try {
          resolve(_item.cc = result.value);
        } catch (e) {
          console.log('getCC Error: ', e);
          resolve(_item.cc = '');
        };
      });
    } else {
      resolve(_item.cc = item.cc);
    };
  }));
};

const getEnd = async (item, _item) => {
  return (new Promise(function (resolve) {
    if (item?.end?.getAsync) {
      item.end.getAsync(function (result) {
        try {
          resolve(_item.end = result.value?.toISOString());
        } catch (e) {
          console.log('getEnd ERROR: ', e);
          resolve(_item.end = item?.dateTimeCreated ? item?.dateTimeCreated?.toISOString() : '');
        };
      });
    } else {
      resolve(_item.end = item.end);
    };
  }));
};

const getFrom = async (item, _item) => {
  return (new Promise(function (resolve) {
    if (item?.from?.getAsync) {
      item.from.getAsync(function (result) {
        try {
          resolve(_item.from = result.value);
        } catch (e) {
          console.log('getFrom Error: ', e);
          resolve(_item.from = '');
        };
      });
    } else {
      resolve(_item.from = item.from);
    };
  }));
};

const getItemRestId = (itemId) => {
  if (Office?.context?.mailbox?.diagnostics?.hostName === 'OutlookIOS') {
    return itemId;
  } else {
    // Convert to an item ID for API v2.0.
    return Office?.context?.mailbox?.convertToRestId ? Office?.context?.mailbox?.convertToRestId(itemId, Office?.MailboxEnums?.RestVersion?.v2_0) : itemId;
  };
};

const getLocation = async (item, _item) => {
  return (new Promise(function (resolve) {
    if (item?.location?.getAsync) {
      item.location.getAsync(function (result) {
        try {
          resolve(_item.location = result.value);
        } catch (e) {
          console.log('getLocation Error: ', e);
          resolve(_item.location = '');
        };
      });
    } else {
      resolve(_item.location = item.location);
    };
  }));
};

const getOutlookRecordIdEmail = async (item) => {
  return (new Promise(function (resolve) {
    const IMID = item?.internetMessageId?.replace('<', '')?.replace('>', '');
    const externalIdFields = [
      { 'CT_PE__Exchange_Internet_Message_Id_255__c': IMID?.substring(0, 255) },
      { 'CT_PE__Exchange_Item_Id_255__c': item?.itemId?.substring(0, 255) },
      { 'CT_PE__Exchange_Thread_Id_255__c': item?.conversationId?.substring(0, 255) },
      { 'CT_PE__Exchange_Internet_Message_Id__c': IMID },
      { 'CT_PE__Exchange_Item_Id__c': item?.itemId },
      { 'CT_PE__Exchange_Thread_Id__c': item?.conversationId },
      { 'CT_PE__Exchange_Composite_Id__c': IMID }
    ];
    if (item?.loadCustomPropertiesAsync) {
      try {
        item?.loadCustomPropertiesAsync(function (asyncResult) {
          let customProps = asyncResult.value;
          if (customProps) {
            const sfdcID = customProps.get('sfdcId');
            if (!sfdcID && !item?.internetMessageId && !item?.itemId && !item?.conversationId) {
              console.log('in draft email');
              resolve({
                draft: true,
                externalId: `draft-${new Date()}`,
                externalIdFields: externalIdFields,
                toast: {
                  message: "Saved interaction may not be seen by all users, please send email before saving",
                  severity: 'info',
                  title: "Currently in draft mode",
                  type: 'alert'
                }
              });
            } else {
              resolve({
                draft: false,
                externalId: IMID ? IMID : item?.itemId ? item?.itemId : sfdcID ? sfdcID : item?.conversationId ? item?.conversationId : null,
                externalIdFields: externalIdFields,
              });
            };
          } else {
            console.log('catch new external id value', item);
            resolve({
              draft: false,
              externalId: IMID ? IMID : item?.itemId ? item?.itemId : item?.conversationId,
              externalIdFields: externalIdFields,
            });
          };
        });
      } catch (e) {
        console.log('sfdcId property could not be set', e);
        resolve({
          draft: false,
          externalId: IMID ? IMID : item?.itemId ? item?.itemId : item?.conversationId,
          externalIdFields: externalIdFields,
        });
      };
    } else {
      console.log('missing async ability');
      resolve({
        draft: false,
        externalId: IMID ? IMID : item?.itemId ? item?.itemId : item?.conversationId,
        externalIdFields: externalIdFields,
      });
    };
  }));
};

const getOutlookRecordIdMeeting = async (item, _item) => {
  return (new Promise(function (resolve) {
    let externalIdFields = [
      { 'CT_PE__Exchange_iCalUID_255__c': '' },
      { 'CT_PE__Exchange_Item_Id_255__c': item?.itemId ? item?.itemId?.substring(0, 255) : _item?.itemId?.substring(0, 255) },
      { 'CT_PE__Exchange_Thread_Id_255__c': item?.seriesId ? item?.seriesId?.substring(0, 255) : _item?.seriesId?.substring(0, 255) },
      { 'CT_PE__Exchange_iCalUID__c': '' },
      { 'CT_PE__Exchange_Item_Id__c': item?.itemId ? item?.itemId : _item?.itemId },
      { 'CT_PE__Exchange_Thread_Id__c': item?.seriesId ? item?.seriesId : _item?.seriesId }
    ];

    getExtendedIds((err, res) => {
      if (!err) {
        // MS changed this - we can now get an ical uid for draft --- need new is draft method
        _item.exchange_iCalUID = res;
        externalIdFields[0]['CT_PE__Exchange_iCalUID_255__c'] = res?.substring(0, 255);
        externalIdFields[3]['CT_PE__Exchange_iCalUID__c'] = res;
        resolve({
          draft: false,
          externalId: res ? res : item?.itemId,
          externalIdFields: externalIdFields,
        });
      } else {
        console.log('no token. - probably draft mode', err);
        resolve({
          draft: true,
          externalId: item?.itemId ? item?.itemId : item?.conversationId ? item?.conversationId : _item?.itemId ? _item?.itemId : _item?.conversationId ? _item?.conversationId : `draft-${new Date()}`,
          externalIdFields: externalIdFields,
        });
      };
    });
  }));
};

const getOptionalAttendees = async (item, _item) => {
  return (new Promise(function (resolve) {
    if (item?.optionalAttendees?.getAsync) {
      item.optionalAttendees.getAsync(function (result) {
        try {
          resolve(_item.optionalAttendees = result.value);
        } catch (e) {
          console.log('getOptionalAttendees ERROR: ', e);
          resolve(_item.optionalAttendees = []);
        };
      });
    } else {
      resolve(_item.optionalAttendees = item.optionalAttendees);
    };
  }));
};

const getOrganizer = async (item, _item) => {
  return (new Promise(function (resolve) {
    if (item?.organizer?.getAsync) {
      item.organizer.getAsync(function (result) {
        try {
          resolve(_item.organizer = result.value);
        } catch (e) {
          console.log('getOrganizer Error: ', e);
          resolve(_item.organizer = {});
        };
      });
    } else {
      resolve(_item.organizer = item.organizer);
    };
  }));
};

const getRequiredAttendees = async (item, _item) => {
  return (new Promise(function (resolve) {
    if (item?.requiredAttendees?.getAsync) {
      item.requiredAttendees.getAsync(function (result) {
        try {
          resolve(_item.requiredAttendees = result.value);
        } catch (e) {
          console.log('getRequiredAttendees ERROR: ', e);
          resolve(_item.requiredAttendees = []);
        };
      });
    } else {
      resolve(_item.requiredAttendees = item.requiredAttendees);
    };
  }));
};

const getSFDCID = async (item, _item) => {
  return (new Promise(function (resolve) {
    if (item?.loadCustomPropertiesAsync) {
      item?.loadCustomPropertiesAsync(function (asyncResult) {
        try {
          let customProps = asyncResult.value;
          const sfdcID = customProps.get('sfdcId');
          resolve(_item.SFDCID = sfdcID);
        } catch (e) {
          console.log('sfdcId property could not be found', e);
          resolve(_item.SFDCID = '');
        };
      });
    } else {
      resolve(_item.SFDCID = '');
    };
  }));
};

const getStart = async (item, _item) => {
  return (new Promise(function (resolve) {
    if (item?.start?.getAsync) {
      item.start.getAsync(function (result) {
        try {
          resolve(_item.start = result.value?.toISOString());
        } catch (e) {
          console.log('getStart ERROR: ', e);
          resolve(_item.start = item.dateTimeCreated ? item.dateTimeCreated?.toISOString() : '');
        };
      });
    } else {
      resolve(_item.start = item.start);
    };
  }));
};

const getSubject = async (item, _item) => {
  return (new Promise(function (resolve) {
    if (item?.subject?.getAsync) {
      item.subject.getAsync(function (result) {
        try {
          resolve(_item.subject = result.value);
        } catch (e) {
          console.log('getSubject Error: ', e);
          resolve(_item.subject = '');
        };
      });
    } else {
      resolve(_item.subject = item.subject);
    };
  }));
};

const getTo = async (item, _item) => {
  return (new Promise(function (resolve) {
    if (item?.to?.getAsync) {
      item.to.getAsync(function (result) {
        try {
          resolve(_item.to = result.value);
        } catch (e) {
          console.log('getTo Error: ', e);
          resolve(_item.to = '');
        };
      });
    } else {
      resolve(_item.to = item.to);
    };
  }));
};

const saveItemAsynch = async () => {
  return new Office.Promise(function (resolve, reject) {
    try {
      Office.context?.mailbox?.item?.saveAsync(function callback (result) {
        resolve(result);
      });
    } catch (error) {
      console.log('Error with outlook save item async', error);
      reject(error);
    };
  });
};


const loadPromiseMailASynch = async (item, _item) => {
  // DO NOT CHANGE ORDER
  const promises = [];
  promises.push(getOutlookRecordIdEmail(item, _item));
  promises.push(getBody(item, _item));
  promises.push(getBodyText(item, _item));
  promises.push(getBCC(item, _item));
  promises.push(getCC(item, _item));
  promises.push(getFrom(item, _item));
  promises.push(getTo(item, _item));
  promises.push(getSubject(item, _item));
  return promises;
};

const loadPromiseMeetingsASynch = async (item, _item, token) => {
  // DO NOT CHANGE ORDER
  const promises = [];
  promises.push(getIcalUidFromGraph(item, _item, token));
  promises.push(getOutlookRecordIdMeeting(item, _item));
  promises.push(getSFDCID(item, _item));
  promises.push(getBody(item, _item));
  promises.push(getBodyText(item, _item));
  promises.push(getEnd(item, _item));
  promises.push(getLocation(item, _item));
  promises.push(getOptionalAttendees(item, _item));
  promises.push(getOrganizer(item, _item));
  promises.push(getRequiredAttendees(item, _item));
  promises.push(getStart(item, _item));
  promises.push(getSubject(item, _item));
  return promises;
};


export { buildOfficeExchangeItem, getOutlookRecordIdEmail, getOutlookRecordIdMeeting, getItemRestId, loadPromiseMailASynch, loadPromiseMeetingsASynch, saveItemAsynch };
