import axios from 'axios';
import { createNestablePublicClientApplication } from "@azure/msal-browser";


let loginDialog;
const dialogLoginUrl = location.protocol + '//' + location.hostname + (location.port ? ':' + location.port : '') + '/login/ms';
const clientId = process.env.REACT_APP_MS_NAA_CLIENT_ID;


const signInO365 = async (setMsAuthUser, setMsAuthToken, msGraphAccessToken, salesforceUser, loadNewItem) => {
  Office.context.ui.displayDialogAsync(dialogLoginUrl, { height: 25, width: 30 }, (result) => {
    if (result.status === Office.AsyncResultStatus.Failed) {
      console.log('error opening dialog', `${result.error.code} ${result.error.message}`)
    } else {
      loginDialog = result.value;
      loginDialog.addEventHandler(Office.EventType.DialogMessageReceived, processLoginMessage);
      loginDialog.addEventHandler(Office.EventType.DialogEventReceived, processLoginDialogEvent);
    };
  });

  const processLoginMessage = (arg) => {
    if (arg.origin !== window.location.origin) {
      console.log('error Incorrect origin passed to processLoginMessage.')
      throw new Error("Incorrect origin passed to processLoginMessage.");
    }

    let messageFromDialog = JSON.parse(arg.message);
    if (messageFromDialog.status === 'success') {
      // console.log('success getting message from popup', messageFromDialog)
      // We now have a valid access token.
      loginDialog.close();
      setMsAuthToken(messageFromDialog.token);
      msGraphAccessToken = messageFromDialog.token;
      // setMsAuthToken({token: messageFromDialog.token, expires: messageFromDialog.expiresOn});
      const officeProfile = Office?.context?.mailbox?.userProfile || {};
      officeProfile.account_type = Office?.context?.mailbox?.userProfile?.accountType || "unavailable";
      officeProfile.diplay_name = Office?.context?.mailbox?.userProfile?.displayName;
      officeProfile.email = Office?.context?.mailbox?.userProfile?.emailAddress;
      officeProfile.ewsUrl = Office.context.mailbox?.ewsUrl || "unavailable";
      officeProfile.hostname = Office.context.mailbox?.diagnostics?.hostName;
      officeProfile.PermissionLevel = Office.context?.mailbox?.initialData?.permissionLevel
      officeProfile.time_zone = Office?.context?.mailbox?.userProfile?.timeZone;
      officeProfile.token = messageFromDialog.token;
      officeProfile.expires = messageFromDialog.expiresOn;

      setMsAuthUser(officeProfile);
      localStorage.setItem('_ms_investorflow', JSON.stringify({token: messageFromDialog.token, expires: messageFromDialog.expiresOn}));
      if (salesforceUser) {
        Office?.context?.mailbox?.addHandlerAsync(Office?.EventType?.ItemChanged, loadNewItem())
      };
      return true;
    } else {
      console.log('failed... Something went wrong with authentication or the authorization of the web application.', result);
      loginDialog.close();
      return false;
    };
  };

  const processLoginDialogEvent = (arg) => {
    processDialogEvent(arg);
  };
};

const logoutFromO365 = async (msAuthUser, setMsAuthUser, setMsAuthToken) => {
  localStorage.removeItem('_ms_investorflow');
  setMsAuthUser(null);
  setMsAuthToken(null);
};

const processDialogEvent = (arg) => {
  switch (arg.error) {
    case 12002:
      console.log('The dialog box has been directed to a page that it cannot find or load, or the URL syntax is invalid.');
      break;
    case 12003:
      console.log('The dialog box has been directed to a URL with the HTTP protocol. HTTPS is required.');
      break;
    case 12006:
      // 12006 means that the user closed the dialog instead of waiting for it to close.
      // It is not known if the user completed the login or logout, so assume the user is
      // logged out and revert to the app's starting state. It does no harm for a user to
      // press the login button again even if the user is logged in.
      break;
    default:
      console.log('Unknown error in dialog box.');
      break;
  };
};


/**
 * Attaches a given access token to a MS Graph API call. Returns information about the user
 * @param accessToken
 * @param url
 */
const callMsGraph = async (accessToken, url) => {
  const options = { headers: { 'Authorization': 'Bearer ' + accessToken, 'Content-Type': 'application/json' } };
  try {
    const graphData = await axios.get(encodeURI(url), options);
    // console.log('graph data', graphData);
    return graphData?.data;
  } catch (error) {
    console.log('error fetching from graph', error);
    if (error?.response?.data?.error?.code === 'InvalidAuthenticationToken') {
      return { error: error.response.data.error, code: 'InvalidAuthenticationToken' };
    };
    return { error: error, url: url };
  };
};

const isNestedAppAuthSupported = () => {
  return Office.context.requirements.isSetSupported("NestedAppAuth", "1.1");
};

const fetchMsTokenSilently = async () => {
  let pca = undefined;
  // Initialize the public client application
  pca = await createNestablePublicClientApplication({
    auth: {
      clientId: clientId,
      authority: "https://login.microsoftonline.com/common"
    },
  });
  // console.log('nested supported', Office.context.requirements.isSetSupported("NestedAppAuth", "1.1"))
  let accessToken = null;
  const tokenRequest = {
    scopes: [
      'Calendars.Read.Shared',
      'Calendars.Read',
      'email',
      'Mail.Read',
      'Mail.Read.Shared',
      'offline_access',
      'openid',
      'profile',
      'User.Read'
    ],
  };
  try {
    // console.log("Trying to acquire token silently...");
    const userAccount = await pca.acquireTokenSilent(tokenRequest);
    // console.log("Acquired token silently.", userAccount);
    accessToken = userAccount.accessToken;
    localStorage.setItem('_ms_investorflow', JSON.stringify({token: userAccount.accessToken, expires: userAccount.expiresOn}));
    return accessToken;
  } catch (error) {
    console.log(`Unable to acquire token silently: ${error}`);
    // console.log('error', JSON.stringify(error))
    if (JSON.stringify(error).includes('AADSTS65001')) {
      console.log('consent_required: AADSTS65001', 'need to have user approve or have their IT approve');
      return { error: 'use dialog' }
    };
  };
};


export { callMsGraph, fetchMsTokenSilently, isNestedAppAuthSupported, logoutFromO365, signInO365 };
